
import { computed, defineComponent, onMounted, ref } from "vue";
import TransactionService from "@/service/transaction-service/TransactionService";
import {
  Transaction,
  TransactionFilter,
  TransactionResponseDetail,
} from "@/types/transaction/Transaction.interface";
import TransactionList from "@/components/transaction/TransactionList.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    TransactionList,
  },
  setup() {
    const transactionList = ref<Transaction[]>([]);
    const transactionService = ref(new TransactionService());
    const store = useStore();
    const filter = ref<TransactionFilter>({
      brandId: 0,
      pageNo: 1,
      perPage: 10,
      userGUID: "string",
    });

    const getTransactionList = async (scrollFlag: boolean, $state?: any) => {
      if (!scrollFlag) {
        filter.value.pageNo = 1;
        transactionList.value = [];
      }

      await transactionService.value
        .getOrderListsByUserId(filter.value)
        .then((data: TransactionResponseDetail) => {
          if (scrollFlag) {
            if (data.detail.length == 0) {
              if ($state) $state.complete();
            } else {
              transactionList.value = [
                ...transactionList.value,
                ...data.detail,
              ];
              if ($state) $state.loaded();
            }
          } else {
            transactionList.value = data.detail;
            if ($state) $state.loaded();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const infiniteHandler = async ($state: any) => {
      setTimeout(async () => {
        filter.value.pageNo++;
        await getTransactionList(true, $state);
      }, 500);
    };

    onMounted(async () => {
      filter.value.brandId = computed(
        () => store.getters.getBrand.brandId
      ).value;
      filter.value.userGUID = computed(
        () => store.getters.getUser.socialReferenceId
      ).value;
      await getTransactionList(false);
    });

    return {
      transactionList,
      infiniteHandler,
    };
  },
});
