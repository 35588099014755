
import { defineComponent, PropType } from "vue";
import InfiniteLoading from "@/components/infinite-loading/InfiniteLoading.vue";
import {
  Transaction,
  TransactionProduct,
} from "@/types/transaction/Transaction.interface";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {
    transactionList: {
      required: true,
      type: Array as PropType<Transaction[]>,
    },
  },
  components: {
    InfiniteLoading,
  },
  emits: ["infiniteHandler"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const infiniteHandler = ($state: any) => {
      emit("infiniteHandler", $state);
    };
    const datetimeFormat = (data: Date) => {
      return moment(data).format("DD MMM YYYY HH:mm:ss");
    };

    const productCount = (products: TransactionProduct[]) => {
      let count = 0;
      products.forEach((product) => {
        count = count + product.quantity;
      });
      return count;
    };

    const onSeeOrderDetail = (transaction: Transaction) => {
      const redirectUri = `/${route.params.initialId}/order/history/detail/${transaction.id}?sessionId=${route.query.sessionId}`;
      router.push(redirectUri);
    };

    return {
      infiniteHandler,
      datetimeFormat,
      productCount,
      onSeeOrderDetail,
    };
  },
});
